<template>
  <dash-page-new
    :title="$t('ViewAll')"
    :root="$t('Dashboard')"
    :subtitle="$t('referral.system')"
    icon="mdi-account-star"
    >
    <template #header_action >
      <v-btn @click="openAddWindow" :color="wsATTENTION" class="noCaps" dark>
        {{ $t('referral.add') }}
      </v-btn>
    </template>

    <template #default>
      <ws-data-table
          :items="items"
          :headers="headers"
          :row-action="openReferralDetails"
          class="mt-5"
      >
        <template #item.name="{item}">
          <h5 :style="`color : ${wsACCENT}`">
            {{ item.name }}
          </h5>
        </template>

        <template #item.code="{item}">
          <v-chip @click.stop="copy(item.code)" :color="wsACCENT" dark>
            <v-icon small class="mr-1">mdi-content-copy</v-icon>
            <h5> {{ item.code }}</h5>
          </v-chip>
        </template>

        <template #item.clicks="{item}">
          <h5>{{ item.clicks }}</h5>
        </template>

        <template #item.users_count="{item}">
          <h5>{{ item.users_count }}</h5>
        </template>

        <template #item.unique_orders_count="{item}">
          <h5>{{ item.unique_orders_count || 0 }}</h5>
        </template>

        <template #item.orders_count="{item}">
          <h5>{{ item.orders_count || 0 }}</h5>
        </template>

        <template #item.orders_total_price="{item}">
          <h5>{{ item.orders_total_price || 0 }}</h5>
        </template>

        <template #item.is_active="{item}">
          <ft-select
              :items="CHOICE_ACTIVE"
              @input="editActiveStatus(item,$event)"
              fill-height
          >
            <v-chip dark :color="item.is_active ? wsSUCCESS : 'grey'">
              <h5 class="text-no-wrap pl-2">
                {{ item.is_active ? $t('Active') : $t('NotActive') }}
                <v-icon>mdi-chevron-down</v-icon>
              </h5>
            </v-chip>
          </ft-select>
        </template>

        <template #item.action="{item}">
          <v-btn @click.stop="openDelete(item)" icon class="noCaps" :color="wsACCENT">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>

      </ws-data-table>
    </template>

    <template #dialog>
      <ws-dialog
          v-model="displayDialog"
          @save="saveReferral"
          :title="$t('referral.add')"
      >
        <ws-text-field
            v-model="entityData.name"
            :label="$t('Name')"
            :placeholder="$t('EnterName')"
        />

        <ws-text-field
            v-model="entityData.code"
            :label="$t('Code')"
            :placeholder="$t('referral.code.enter')"
            :error="codeError"
            @input="codeError = false"
            clearable
            class="mt-5"
        />

<!--        <ws-select-->
<!--            v-model="entityData.manager_id"-->
<!--            :items="managersSelect"-->
<!--            :label="$t('Manager')"-->
<!--            :placeholder="$t('ChooseManager')"-->
<!--            clearable-->
<!--            class="mt-5"-->
<!--            autocomplete-->
<!--        />-->
<!--        <h5 class="font-weight-regular wsACCENT mt-1">{{$t('referral.manager.tooltip')}}</h5>-->

        <v-row>
          <v-col>
            <ws-select
                v-model="entityData.commission_type"
                :items="commissionTypesSelect"
                :label="$t('referral.commission_type.name')"
                :placeholder="$t('referral.commission_type.choose')"
                class="mt-5"
            />
          </v-col>
          <v-col>
            <ws-text-field
                v-if="entityData.commission_type === 'percent'"
                v-model="entityData.percent"
                :label="$t('referral.percent.name')"
                :placeholder="$t('referral.percent.enter')"
                :error="codeError"
                @input="codeError = false"
                clearable
                number
                prepend-inner-icon="mdi-percent-circle"
                key="percent"
                class="mt-5"
            />
            <ws-text-field
                v-else-if="entityData.commission_type === 'sum'"
                v-model="entityData.order_price"
                :label="$t('Sum')"
                :placeholder="$t('referral.sum.enter')"
                :error="codeError"
                @input="codeError = false"
                clearable
                number
                class="mt-5"
                key="sum"
            />
          </v-col>
        </v-row>
        <h5 class="wsACCENT font-weight-regular mt-n6"  v-if="entityData.commission_type === 'percent'" >
          {{ $t('referral.percent.description') }}
        </h5>
        <h5 class="wsACCENT font-weight-regular mt-n6"  v-if="entityData.commission_type === 'sum'" >
          {{ $t('referral.sum.description') }}
        </h5>
        <v-row>
          <v-col>
            <ws-text-field
                v-model="entityData.user_price"
                :label="$t('referral.user_price.name')"
                :placeholder="$t('referral.user_price.enter')"
                :error="codeError"
                @input="codeError = false"
                clearable
                number
                class="mt-5"
            />
          </v-col>
          <v-col>
            <ws-text-field
                v-model="entityData.click_price"
                :label="$t('referral.click_price.name')"
                :placeholder="$t('referral.click_price.enter')"
                :error="codeError"
                @input="codeError = false"
                clearable
                number
                class="mt-5"
            />
          </v-col>
        </v-row>

        <ws-switch
            v-model="entityData.is_active"
            :placeholder="entityData.is_active ? $t('Active') : $t('NotActive')"
            class="mt-3"
        />
      </ws-dialog>
      <ws-dialog
          v-model="displayDelete"
          :title="$t('referral.delete')"
          display-confirm-delete
          close-delete
          @delete="deleteReferral"
      ></ws-dialog>
    </template>

  </dash-page-new>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "BusinessReferral",
  data() {
    return {
      items : [],
      entityData : {},
      selectedItem : {},
      displayDialog : false,
      addNew : false,
      displayDelete : false,
      codeError : false,
      managersSelect : []
    }
  },
  computed : {
    headers() {
      return [
        { text : this.$t('Name') , value : 'name' },
        { text : this.$t('Code') , value : 'code' },
        { text : this.$t('referral.clicks') , value : 'clicks' },
        { text : this.$t('Users') , value : 'users_count' },
        // { text : this.$t('referral.unique_users') , value : 'unique_orders_count' },
        { text : this.$t('Orders') , value : 'orders_count' },
        // { text : this.$t('referral.total_price') , value : 'orders_total_price' },
        { text : this.$t('Active') , value : 'is_active' },
        { value : 'action' },
      ]
    },
    commissionTypesSelect() {
      return [
        { text : this.$t('Percent') , value : 'percent' },
        { text : this.$t('Sum') , value : 'sum' }
      ]
    }
  },
  methods : {
    ...mapActions('referral' , [
        'GET_REFERRALS',
        'ADD_EDIT_REFERRAL',
        'DELETE_REFERRAL'
    ]),

    ...mapActions('businessUsers' , [
      'GET_MANAGERS_SELECT',
    ]),



    openReferralDetails(item) {
      this.$router.push(this.businessDashLink(`referral/${item.code}`))
    },

    async deleteReferral() {

      let result = await this.DELETE_REFERRAL(this.selectedItem.uuid)
      if ( !result ) {
        return
      }
      let index = this.items.findIndex(el => el.uuid === this.selectedItem.uuid)
      if ( index === -1 ) {
        return
      }
      this.notify(this.$t('referral.deleted'))

      this.displayDelete = false
      this.items.splice(index, 1)
    },
    openDelete(item) {
      this.selectedItem = item
      this.displayDelete = true
    },
    editActiveStatus(item , status) {
      this.addNew = false
      this.entityData = {
        uuid : item.uuid,
        is_active : status
      }
      this.saveReferral()
    },

    validateCode() {
      if ( this.entityData.code && !this.entityData.code.match("^[a-zA-Z0-9_-]*$")) {
        this.codeError = this.$t('referral.code.regex_error')
        this.notify(this.$t('referral.code.regex_error') , 'warning')
        return false
      }
      return true
    },
    async saveReferral() {

      if ( this.entityData.percent > 100 ) {
        this.entityData.percent = 100
      }

      if ( this.entityData.percent < 0 ) {
        this.entityData.percent = 0
      }

      if ( this.entityData.order_price < 0 ) {
        this.entityData.order_price = null
      }

      if ( this.entityData.user_price < 0 ) {
        this.entityData.user_price = null
      }

      if ( this.entityData.click_price < 0 ) {
        this.entityData.click_price = null
      }

      if ( !this.validateCode() ) {
        return
      }

      let result = await this.ADD_EDIT_REFERRAL(this.entityData)
      if ( !result )  {
        return
      }
      if (this.addNew) {
        this.items.push(result)
        this.notify(this.$t('ReferralCreated') , 'success')
        this.displayDialog = false
      } else {
        let index = this.items.findIndex(el => el.uuid === result.uuid)
        if ( index === -1 ) {
          return
        }
        this.$set(this.items, index , result )
        this.notify(this.$t('ChangesSaved'))
        this.displayDialog = false
      }

    },

    openAddWindow() {
      this.addNew = true
      this.entityData = {
        code : null,
        commission_type : "percent",
        is_active : true
      }
      this.displayDialog = true
    },
    openEditWindow(item) {
      this.addNew = false
      this.entityData = Object.assign({} , item)
      this.displayDialog = true
    },

    // Technical
    async copy(text) {
      try {
        let rootPlace = this.$store.state.domain ? `${location.host}/` : `westudy.ua/${this.$store.state.business.selectedBusiness.alias}`
        text = "https://" + rootPlace + "?ref=" + text
        await navigator.clipboard.writeText(text);
        this.notify(this.$t('CopiedToClipboard') )
      } catch($e) {
        this.notify(this.$t('Error') )
      }
    },

    async initPage() {
      let result = await this.GET_REFERRALS()
      if ( !result || result === true) {
        return
      }
      this.items = result
      this.managersSelect = await this.GET_MANAGERS_SELECT()
      this.managersSelect = this.managersSelect === true ? [] : this.managersSelect
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>